import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import Usernavbar from "./navbar/Navbar";

const PaymentCallback = () => {
  const [status, setStatus] = useState("Processing");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const verifyPayment = async () => {
      const params = new URLSearchParams(location.search);
      const reference = params.get("reference");

      if (reference) {
        try {
          const response = await axios.get(
            `/api/paystack/verify-transaction/${reference}`
          );
          if (response.data.verified) {
            setStatus("Success");
            setTimeout(() => navigate("/profile"), 3000);
          } else {
            setStatus("Failed");
            setTimeout(() => navigate("/rooms"), 3000);
          }
        } catch (error) {
          console.error("Error verifying payment:", error);
          setStatus("An error occurred. Please contact support.");
        }
      } else {
        setStatus("Invalid payment reference.");
        setTimeout(() => navigate("/rooms"), 3000);
      }
    };

    verifyPayment();
  }, [location, navigate]);

  return (
    <div>
      <Usernavbar />

      {status === "Processing" ? (
        <div>
          <p>Please Wait, Processing....</p>
          <DotLottieReact
            src="https://lottie.host/743b1cd3-27c7-458b-a21c-884e1811a1a6/FTV12V1xzG.lottie"
            loop
            autoplay
          />
        </div>
      ) : status === "Success" ? (
        <div>
          <p>Payment successfully made ! Redirecting...</p>
          <DotLottieReact
            src="https://lottie.host/ce09e276-10a9-45c8-b384-8aedbdd1291e/QHS37eiLUB.lottie"
            loop
            autoplay
          />
        </div>
      ) : status === "Failed" ? (
        <div>
          <p>Payment NOT made! Redirecting...</p>
          <DotLottieReact
            src="https://lottie.host/8e3508c7-d340-4d3f-b9ff-a1c4d221c32a/ukRuTjQw4Y.lottie"
            loop
            autoplay
          />
        </div>
      ) : (
        <div>
          <p>An Error Occured, try again later. Redirecting ...</p>
          <DotLottieReact
            src="https://lottie.host/ad65a353-630c-495d-b10d-bb9b3939b222/In7uSGlmRw.lottie"
            loop
            autoplay
          />
        </div>
      )}
    </div>
  );
};

export default PaymentCallback;
