import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { NextUIProvider } from "@nextui-org/system";
import Home from "./pages/home/Home";
import { useAuth } from "./context/AuthContext";
import List from "./pages/list/List";
import SignIn from "./pages/login/SignIn";
import axios from "axios";
import UserProfile from "./pages/profile/UserProfile";
import PaymentCallback from "./components/PaystackCallback";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";

// Set the base URL for Axios globally
axios.defaults.baseURL = "https://back.showers-hostel.com/";

function ProtectedRoute({ children }) {
  const { isAuthenticated, loading } = useAuth();

  if (loading) {
    return (
      <DotLottieReact
        src="https://lottie.host/743b1cd3-27c7-458b-a21c-884e1811a1a6/FTV12V1xzG.lottie"
        loop
        autoplay
      />
    );
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return children;
}

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/home" element={<Home />} />
      <Route path="/rooms" element={<List />} />
      <Route
        path="/profile"
        element={
          <ProtectedRoute>
            <UserProfile />
          </ProtectedRoute>
        }
      />
      <Route path="/payment-callback" element={<PaymentCallback />} />
      <Route path="/login" element={<SignIn />} />
    </Routes>
  );
}

function App() {
  const { loading } = useAuth();
  if (loading) {
    return (
      <DotLottieReact
        src="https://lottie.host/743b1cd3-27c7-458b-a21c-884e1811a1a6/FTV12V1xzG.lottie"
        loop
        autoplay
      />
    );
  }

  return (
    <NextUIProvider>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </NextUIProvider>
  );
}

export default App;
